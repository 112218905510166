import React,{useState} from 'react'

const Apk = () => {
    const [download, setdownload] = useState("Download Mountreach APK")
    const handledownload=()=>{
        window.open('https://www.amazon.com/dp/B0CW6CF2RB/ref=apps_sf_sta')
        setdownload("Downloading")
        setTimeout(() => {
            setdownload("Downloaded")
        }, 3000);
    }

  return (
    <div className='column reverse m0' style={{display:'flex',alignItems:"center",margin:'50px 15%',marginTop:"80px"}}>
        <div>
                <section id="download-apk" style={{ textAlign: 'center', padding: '20px' }}>
            <div className="apk-container">
                <h2>Download Mountreach App</h2>
                <p>
                Connect with farmers and traders seamlessly with the Mountreach mobile app. Download the APK to get started with bidding, accessing technical and financial support, and much more.
                </p>
                {/* <a href="/path-to-your-apk-file.apk" download> */}
                <div style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                <div className='zoom' onClick={handledownload} style={{
                    padding: '10px 20px', 
                    backgroundColor: '#4CAF50', 
                    color: 'white', 
                    border: 'none', 
                    cursor: 'pointer',
                    display:"flex",
                    borderRadius:5,
                }}>
                    {download==="Download Mountreach APK"&&<i style={{marginRight:5}} className='fa fa-download'/>}
                    <div>
                    {download}
                    </div>
                     {download==="Downloading"&&<div class="loader"></div>}
                </div>
                </div>
                {/* </a> */}
            </div>
            </section>
        </div>
    {/* mobile apk */}
    <div>
        <div data-aos='zoom-in' style={{position:"relative",textAlign:"center"}}>
        <img className='w100' style={{width:400}} src='https://png.pngtree.com/png-clipart/20240331/original/pngtree-android-mobile-frame-vector-png-image_14724214.png' alt="Image"/>
        {/* <img style={{width:170,height:363,borderRadius:"20px", objectFit:"cover",position:"absolute",left:"50%",top:"50%",transform:"translate(-50%,-50%)"}} src='https://www.foodnavigator.com/var/wrbm_gb_food_pharma/storage/images/publications/food-beverage-nutrition/foodnavigator.com/news/policy/agri-food-policy-reform-in-uk-and-eu-may-raise-prices-farmers-warn/11991942-1-eng-GB/Agri-food-policy-reform-in-UK-and-EU-may-raise-prices-farmers-warn.jpg' alt="Image"/> */}
        <img width={"45%"} src={'mountlogo.png'} style={{objectFit:"cover",position:"absolute",left:"50%",top:"50%",transform:"translate(-50%,-50%)"}} alt='logo'/>
        </div>
    </div>
    </div>
  )
}

export default Apk
