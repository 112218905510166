import React from 'react'
import './choose.css';

function ChooseUs() {
  return (
    <div className='cbody'>
      <div class="ccontainer">
   <div class="cleft-section" data-aos="fade-right">
    <img alt="A basket of fresh vegetables including tomatoes and greens" height="600" src="https://cdn.pixabay.com/photo/2021/06/28/18/36/cereals-6372410_1280.jpg" width="400"/>
   </div>
   <div class="cright-section">
    <h2 data-aos="fade-left">
     We Are Different
     <br/>
     <br/>
     From Other Farming
    </h2>
    <br/>

    <p data-aos="fade-left">
     <span>
     At The Smart Grains, we go beyond conventional farming with innovative, eco-friendly practices.
     </span>
    </p>
    <p data-aos="fade-left">
    Our focus on sustainability, cutting-edge technology, and farmer collaboration sets us apart. We cultivate not just crops, but a future of healthier, smarter agriculture.
    </p>
    <div class="features">
     <div class="feature">
      <i class="fas fa-leaf">
      </i>
      <p data-aos="fade-left">
       <span>
        100% Naturally
       </span>
       Free from chemicals, ensuring purity you can trust.
      </p>
     </div>
     <div class="feature">
      <i class="fas fa-cogs">
      </i>
      <p data-aos="fade-left">
       <span>
        High-tech Processing
       </span>
       Clean, safe, and top-quality products every time.
      </p>
     </div>
     <div class="feature">
      <i class="fas fa-truck">
      </i>
      <p data-aos="fade-left">
       <span>
        Home Delivery Service
       </span>
       At doorstep with our dependable home delivery.
      </p>
     </div>
     <div class="feature">
      <i class="fas fa-box">
      </i>
      <p data-aos="fade-left">
       <span>
        Best Quality Product
       </span>
       Superior taste and nutrition in every bite.
      </p>
     </div>
    </div>
    {/* <a data-aos="fade-left" class="button" href="#">
     Get In Touch
    </a> */}
   </div>
  </div>
    </div>
  )
}

export default ChooseUs
