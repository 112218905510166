import './App.css';
import React,{lazy, useState} from 'react';
import Footer from './Components/Footer';
import Socialmedia from './Components/Socialmedia';
import Navbar from './Components/Navbar';
import { createBrowserRouter, RouterProvider,Outlet } from 'react-router-dom';
import Home from './Pages/Home';
import ImageCarousel from './Components/ImageCarousel';
import Header from './Components/Header';
import Whymount from './Components/Whymount';
import Bidding from './Components/Bidding';
import About from './Components/About';
import Blogs from './Components/Blogs';
import ChooseUs from './Components/chooseus/ChooseUs';
import Apk from './Components/Apk';



function App() {
  const handleScroll = (scroll) => {
      const element= document.getElementById(scroll)
      element.scrollIntoView({behavior:"smooth"})
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <div className="App">
           <section id='Home'>
           <Navbar scroll={handleScroll} />
            </section>
           <Header/>
            <Whymount/>
            <section style={{background:`url("https://www.marsdd.com/wp-content/uploads/2021/06/CROP.jpg") no-repeat center`,height:"75vh",color:"white"}}>
              <div style={{background:"#00000091",height:"100%"}}>
                <Bidding/>
              </div>
            </section>
            <section id='About'>
            <About/>
            </section>

            <section id='Choose'>
            <ChooseUs/>
            </section>

            <section id='Blogs'>
            <Blogs/>
            </section>
            <section id='Apk'>
              <Apk/>
            </section>
         
          <div>
            <Outlet />
          </div>
          {/* <Contact /> */}
          <div id='contact' className='leaves' style={{background:'url(img2.jpg)',padding:"20px 0",backgroundPosition:"right bottom"}}>
          <Socialmedia scroll={handleScroll}/>
          </div>
          <Footer />
          {/* <ImageCarousel/> */}
         
        </div>
      ),
      children: [
        {
          path: "/",
          element: <Home/>,
        }
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
    

  );
}

export default App;

