import React,{useEffect, useState} from 'react'
import Blogs from '../Components/Blogs'
import { Helmet } from 'react-helmet-async';
import About from '../Components/About';
import Bidding from '../Components/Bidding';
import Apk from '../Components/Apk';
import Whymount from '../Components/Whymount';
import Header from '../Components/Header';


const Home = () => {

  
  
  return (
  <>
    {/* <Helmet>
    <title>Mountreach-Farmer Producer Company</title>
    <link rel="canonical" href="/" />
  </Helmet> */}
      {/* <Header/>
      <Whymount/>
      <section style={{background:`url("https://www.marsdd.com/wp-content/uploads/2021/06/CROP.jpg") no-repeat`,height:"75vh",color:"white"}}>
        <div style={{background:"#00000091",height:"100%"}}>
          <Bidding/>
        </div>
      </section>
      <section id='About'>
      <About/>
      </section>
      <Blogs/>
      <section id='Apk'>
        <Apk/>
      </section> */}
    </>
  )
}

export default Home
