import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    infinite: true,
    autoplay:  true,
    autoplaySpeed: 3000,
    className: "center",
    centerMode: true,
    centerPadding: "0",
    slidesToShow: 1,
    slidesToScroll: 1,
  
  };
const Bidding = () => {
  return (

    <div className='bid' style={{margin:'50px 20%',height:"100%",paddingTop:"5%"}}>
        <h1 style={{textAlign:"center",fontSize:"4vw",marginBottom:20,color:'white'}}>Why Mountreach ?</h1>
        <div 
    style={{
    margin: '20px 15px',
    paddingTop: '5%',
    
  }}>
      <Slider {...settings}>

    <div >
        <div style={{padding:'20px 0',textAlign:"center"}}>
        <i style={{fontSize:60,marginBottom:20,color:'#65a0f3'}} class="fa-solid fa-link"></i>
        <h2 style={{margin:0,color:"White"}}>Seamless Farmer-Trader Connection</h2>
        <p>Farmers can easily register on our platform to showcase their products to a wide range of traders. Traders, in turn, can browse these listings, place bids, and connect with the farmers for transactions. This direct interaction ensures better pricing and faster deals.</p>
        </div>
    </div>
    <div >
        <div style={{padding:'20px 0',textAlign:"center"}}>
        <i style={{fontSize:60,marginBottom:20,color:'#9fd087'}} class="fa-solid fa-wand-magic-sparkles"></i>
        <h2 style={{margin:0,color:"white"}}>Product Bidding</h2>
        <p>Our bidding system ensures that farmers receive competitive offers for their products. Traders can bid on a variety of agricultural commodities such as grains, pulses, oilseeds, and vegetables, ensuring a transparent and fair trade environment.</p>
        </div>
    </div>
    <div >
        <div style={{padding:'20px 0',textAlign:"center"}}>
        <i style={{fontSize:60,marginBottom:20,color:'#ffcb56'}} class="fa-solid fa-hand-holding-dollar"></i>
        <h2 style={{margin:0,color:"white"}}>Financial Assistance</h2>
        <p>To help farmers scale their operations, we offer access to financial services, including loans, grants, and subsidies. Our platform connects farmers with financial institutions that specialize in agricultural funding, ensuring they have the resources to invest in their crops and grow their businesses.</p>
        </div>
    </div>
    <div >
        <div style={{padding:'20px 0',textAlign:"center"}}>
        <i style={{fontSize:60,marginBottom:20,color:'#6ef7ff'}} class="fa-brands fa-squarespace"></i>
        <h2 style={{margin:0,color:"white"}}>Farmer Education and Resources</h2>
        <p>We believe in empowering farmers with knowledge. Mountreach offers educational content on topics such as crop rotation, soil health, and pest control. We also provide regular updates on market trends and pricing, so farmers can make informed decisions.</p>
        </div>
    </div>
    <div >
        <div style={{padding:'20px 0',textAlign:"center"}}>
        <i style={{fontSize:60,marginBottom:20,color:'#dbf178'}} class="fa-solid fa-handshake-simple"></i>
        <h2 style={{margin:0,color:"white"}}>Comprehensive Support for Traders</h2>
        <p>Traders benefit from an extensive network of registered farmers and diverse agricultural products. Our platform ensures quality control and provides traders with transparent pricing and product information to facilitate smooth transactions.</p>
        </div>
    </div>
      </Slider>
    </div>
    </div>
  )
}

export default Bidding
