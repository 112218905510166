import React, { useState } from 'react'
import { Link } from 'react-router-dom'


const Navbar = ({scroll}) => {
  const [show, setshow] = useState(false)
  const [active, setActive] = useState('/');

  const toggle=()=>{
    const element = document.getElementById('mobile-nav');
    console.log(element)
    !show?element.classList.add('hide-menu'):element.classList.remove('hide-menu');
    setshow(!show)
   
  }
  return (  
    <>
    <div style={{position:"relative"}}>
      {/* <img src='mountain.jpg' alt='Error' style={{objectFit:"cover",width:"100vw",height:"100vh",backgroundPosition:"top"}}/> */}
      {/* <img src='https://www.etftrends.com/wp-content/uploads/2021/03/Agriculture-ETFs-Surge-Following-Surprise-USDA-Report.jpg' alt='image' style={{objectFit:"cover",width:"100vw",height:"100vh",position:"relative"}}/> */}
      {/* <div style={{position:"absolute",top:"40%",left:"20%",color:"white",fontSize:20,width:"50%"}}>
        <h1>Welcome to Mountreach Farmer Producer Company Limited</h1>
      </div> */}

          <div class="navbar Desktop">

          <div class="logo">
          <img className='logo-img' src={"logo.png"} alt='img'/>
          {/* Mountreach */}
          </div>

          <div class="menu">
          <a onClick={() => setActive('/Quality')} href="#">
          Home
          </a>
          <a onClick={() => (setActive('/About'),scroll('About'))} href="#">
          About
          </a>
          <a onClick={() => (setActive('/Products'),scroll('Blogs'))} href="#">
          Blogs
          </a>
          <a onClick={() => (setActive('/Contact'),scroll('contact'))} href="#">
          Contact
          </a>
          </div>
          <div class="menu">
          <a onClick={()=>(scroll('Apk'))} className="btn" href="#">
          Get App
          </a>
          </div>
        

          </div>

          <div className='Mobile'>
        <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",padding:"0px 15px" ,zIndex:5,position:"relative"}}>
          <Link className='link' to={"/"}><img width={100} src={'mountlogo.png'} style={{objectFit:"cover"}} alt='logo'/> </Link>
          <i onClick={toggle} style={{fontSize:20}} className={show?'fa fa-xmark':'fa fa-bars'}/>
        </div>
        {/* <hr style={{margin:0}}/> */}
        {/* <ul className={`hide-menu ${show?'display' : ''}`} style={{textTransform:"uppercase",padding:0,display:"flex",flexDirection:"column",listStyleType:"none",justifyContent:"center",alignItems:"center",gap:20,fontWeight:500,transition:"2s ease"}}> */}
       
      </div>
   
      {/* <div  className='Desktop' style={{top:0,left:0,width:'100%',color:"white"}} >
  
      <div style={{display:"flex",justifyContent:'space-around',alignItems:"center",padding:0}}>
        
      <Link className='link' to={"/"}><img width={100} src={'mountlogo.png'} style={{objectFit:"cover"}} alt='logo'/></Link>
        <ul style={{textTransform:"uppercase",display:"flex",listStyleType:"none",justifyContent:"center",alignItems:"center",gap:20,fontWeight:700}}>
            <li  onClick={() =>setActive('/')} ><Link className={active==="/"?'active':'nav link'} to="/">Home</Link></li> 
            <li onClick={() => (setActive('/About'),scroll('About'))} className={active==="/About"?'active':'nav link'}>About</li>
            <li onClick={() => setActive('/Product')} className={active==="/Product"?'active':'nav'}>Services</li> 
            <li onClick={() => (setActive('/Contact'))} className={active==="/Contact"?'active':'nav'}>Contact</li>
            <li className='button' onClick={()=>scroll('Apk')} style={{border:"1px solid red" ,borderRadius:15,padding:'5px 15px',color:"#dc143c",cursor: 'pointer'}}>Use App</li>
        </ul>
        </div>
      </div> */}
      {/* <div className='Mobile'>
        <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",padding:"0px 15px"}}>
          <Link className='link' to={"/"}><img width={100} src={'mountlogo.png'} style={{objectFit:"cover"}} alt='logo'/> </Link>
          <i onClick={toggle} style={{fontSize:20}} className={show?'fa fa-xmark':'fa fa-bars'}/>
        </div>
        <hr style={{margin:0}}/>
        {show&&<><ul style={{textTransform:"uppercase",padding:0,display:"flex",flexDirection:"column",listStyleType:"none",justifyContent:"center",alignItems:"center",gap:20,fontWeight:500}}>
            <li onClick={() =>setActive('/')} ><Link className={active==="/"?'active':'nav link'} to="/">Home</Link></li> 
            <li onClick={() => (setActive('/About'),setshow(false),scroll('About'))}  className={active==="/About"?'active':'nav link'}>About</li>
            <li onClick={() => setActive('/Quality')} className={active==="/Quality"?'active':'nav'}>Services</li>
            <li onClick={() => setActive('/Contact')} className={active==="/Contact"?'active':'nav'}>Contact</li>
            <li className='button' onClick={()=>(scroll('Apk'),setshow(false))} style={{border:"1px solid red" ,borderRadius:15,padding:'5px 15px',color:"#dc143c"}}>Use App</li>
        </ul>
        <hr/>
        </>}
      </div> */}
    </div >
    <div id='mobile-nav' className={`mobile-navbar  ${show?'display':''}`}>
       <ul >
       <li onClick={() =>setActive('/')} className={active==="/"?'active':'nav link'} to="/">Home</li> 
       <li onClick={() => (setActive('/About'),setshow(false),scroll('About'))}  className={active==="/About"?'active':'nav link'}>About</li>
       <li onClick={() => (setActive('/Quality'),setshow(false),scroll('Blogs'))} className={active==="/Quality"?'active':'nav'}>Blogs</li>
       <li onClick={() => (setActive('/Contact'),setshow(false),scroll('contact'))} className={active==="/Contact"?'active':'nav'}>Contact</li>
       {/* <li className='button' onClick={()=>(scroll('Apk'),setshow(false))} style={{border:"1px solid red" ,borderRadius:15,padding:'5px 15px',color:"#dc143c"}}>Use App</li> */}
       <div  class="menu">
     <a onClick={()=>(scroll('Apk'),setshow(false))} className="btn" href="#">
     Get App
     </a>
     </div>
   </ul>
     </div>
   </>
  )
}

export default Navbar
