import React from 'react'

const Whymount = () => {
  return (
    <>
    <div style={{
        textAlign:'center',
        marginTop:50
    }}>
    <h2>
        Our Service
       </h2>
       <h1>
        What We Provide
       </h1>
    </div>
    <div className='column m0' style={{display:"flex",margin: '10px 8%',justifyContent:"space-evenly",textAlign:"center"}}>



 
    <div className='w100'  style={{
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        overflow:'hidden'
    }}>
       
        <div style={{display:"grid",placeItems:"center",width:"100%"}}>
        <img className='zoom'  style={{ width:"100%",height:150,borderRadius:8,objectFit:"cover"}} src='https://www.agriasia.in/agri/wp-content/uploads/2020/05/india-farm-1000x500.jpg' alt='img'/>
        </div>
        <div style={{display:"grid",placeItems:"center",fontSize:40,margin:"20px 0 -25px",color:"#156bb4"}}>
        <i  className="fa-solid fa-hand-fist"/>
        </div>
        <div style={{padding:25}}>
        <h2 style={{margin:0}}>Farmer Empowerment</h2>
        <p>We work closely with farmers to provide them with the best seeds, fertilizers, and agricultural inputs to enhance yield and quality.</p>
        </div>
    </div>


    <div className='reverse w100' style={{
        // background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        overflow:'hidden'
    }}>
        <div>
       <div style={{display:"grid",placeItems:"center",fontSize:40,color:"#156bb4",marginTop:20}}>
        <i  className="fa-solid fa-recycle"/>
        </div>
        <div style={{padding:'0px 20px 10px 20px'}}>
        <h2 style={{margin:0}}>Sustainable Practices</h2>
        <p>Sustainability is at the core of our operations. We promote environmentally friendly farming methods that preserve natural resources for future generations.</p>
        </div>
        </div>
        <div>
        <img className='zoom' style={{ width:"100%",height:150,borderRadius:8,objectFit:"cover"}} src='https://www.whitleystimpson.co.uk/wp-content/uploads/2022/12/3-1080x675.png' alt='img'/>
        </div>
    </div>


    <div className='w100' style={{
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        overflow:'hidden'

    }}>
        <div style={{display:"grid",placeItems:"center"}}>
        <img className='zoom' style={{
            width:"100%",height:150,borderRadius:8,objectFit:"cover"
        }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH0at72NuV8lkHK0F_zld-u2InLswD4chyrmBsoQJRonYp8aJzIE8x8TQS9SAa7bt4n3Q&usqp=CAU' alt='img'/>
        </div>
        <div style={{display:"grid",placeItems:"center",fontSize:40,margin:"20px 0 -10px",color:"#156bb4"}}>
        <i  className="fa-solid fa-microchip"/>
        </div>
        <div style={{padding:'10px 20px 20px 20px'}}>
        <h2 style={{margin:0}}>Technology Integration</h2>
        <p>	By integrating modern technology into farming practices, we enable farmers to optimize their operations, reduce waste, and improve efficiency.</p>
        </div>
        
    </div>

    </div>
    </>
  )
}

export default Whymount
