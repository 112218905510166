import React, { useState, useEffect, useRef } from 'react';
import useInterval from 'react-use/lib/useInterval';

const About = () => {
  const [clientSatisfaction, setClientSatisfaction] = useState(0);
  const [farmersCount, setFarmersCount] = useState(0);
  const [clientsCount, setClientsCount] = useState(0);
  const [freshFood, setFreshFood] = useState(0);
  const clientSatisfactionRef = useRef(null);
  const farmersRef = useRef(null);
  const clientsRef = useRef(null);
  const freshFoodRef = useRef(null);

  const [typedText, setTypedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [delay, setDelay] = useState(25); // Adjust the delay as needed
  const typingRef = useRef(null);

  const handleTyping = () => {
    const text="At Mountreach, our mission is to revolutionize the agricultural ecosystem by bridging the gap between farmers and traders on a single, unified platform. We aim to empower farmers by providing them with the tools, resources, and opportunities they need to thrive in today’s market-driven economy."
    if (typedText.length < text.length) {
      setTypedText(typedText + text[typedText.length]);
    } else {
      setIsTyping(false);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsTyping(true);
      } else {
        setIsTyping(false);
      }
    }, { threshold: 0.5 }); // Start typing when 50% visible

    observer.observe(typingRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useInterval(handleTyping, isTyping ? delay : null);

  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === clientSatisfactionRef.current) {
            incrementCounter(setClientSatisfaction, 95);
          } else if (entry.target === farmersRef.current) {
            incrementCounter(setFarmersCount, 100);
          } else if (entry.target === clientsRef.current) {
            incrementCounter(setClientsCount, 400);
          } else if (entry.target === freshFoodRef.current) {
            incrementCounter(setFreshFood, 100);
          }
          observer.unobserve(entry.target); // Stop observing once the animation is triggered
        }
      });
    };

    const observerOptions = { threshold: 0.5 }; // Trigger when 50% of the element is visible
    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (clientSatisfactionRef.current) observer.observe(clientSatisfactionRef.current);
    if (farmersRef.current) observer.observe(farmersRef.current);
    if (clientsRef.current) observer.observe(clientsRef.current);
    if (freshFoodRef.current) observer.observe(freshFoodRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const incrementCounter = (setter, maxValue) => {
    let counter = 0;
    const timer = setInterval(() => {
      setter((prev) => {
        if (prev < maxValue) {
          counter = prev + 1;
          return counter;
        } else {
          clearInterval(timer);
          return maxValue;
        }
      });
    }, maxValue>100?15/(maxValue/100): 15); // Speed of increment
  };

  return (
    <div  style={{display:"flex",justifyContent:"center",width:"100vw"}}>
    <div className='w100' style={{margin: "30px 0",width:"70%"}}>
      <h3  style={{ color: "grey", margin: 0, marginLeft:10 }}>About Us</h3>
      <div className='column' style={{display: "flex",width:"100%"}}>
        <h1 className='w100' style={{color:"#156bb4",width:"50%"}}> Cultivating a Future of Sustainable Agriculture</h1>
        <div className='w100' style={{width:"50%"}}>
          <p ref={typingRef}>{typedText}{isTyping && <span className="typing-cursor">|</span>}</p>
        </div>
      </div>
      <div style={{overflow:"hidden",borderRadius:20}} >
        <img className='zoom' data-aos='zoom-in'   src='https://imageio.forbes.com/specials-images/imageserve/64baf4070d38390e6f9a6bb7/0x0.jpg?format=jpg&crop=1066,600,x0,y97,safe&height=900&width=1600&fit=bounds' style={{ width: "100%", borderRadius: "20px", objectFit: "cover", height: 300 }} alt="Error" />
      </div>
      <div className='clients' style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: 25, margin: "20px 0", textAlign: 'center' }}>
        
        <div ref={clientSatisfactionRef} style={{ borderRadius: 20, background: "#99a39d29", padding: "10px 20px", textAlign: "center" }}>
          <h1>{clientSatisfaction}%</h1>
          <h3>
            <i style={{ fontSize: 20 }} className="fa-regular fa-thumbs-up" /> Client Satisfaction
          </h3>
        </div>

        <div ref={farmersRef} style={{ borderRadius: 20, background: "#99a39d29", padding: "10px 20px" }}>
          <h1>{farmersCount}+</h1>
          <h3> <i style={{ fontSize: 20 }} className="fa fa-people-group" /> Farmers in the Farm</h3>
        </div>

        <div ref={clientsRef} style={{ borderRadius: 20, background: "#99a39d29", padding: "10px 20px" }}>
          <h1>{clientsCount}+</h1>
          <h3> <i style={{ fontSize: 20 }} className="fa fa-people-group" /> Total Clients</h3>
        </div>

        <div ref={freshFoodRef} style={{ borderRadius: 20, background: "#99a39d29", padding: "10px 20px" }}>
          <h1>{freshFood}%</h1>
          <h3> <i style={{ fontSize: 20 }} className="fa fa-pagelines" /> Fresh Food</h3>
        </div>

      </div>
    </div>
    </div>
  );
};

export default About
