import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div style={{padding:20,background:`url(${'https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/foot-bg.png'}) repeat`,color:"#cccccc",fontSize:10}}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <p>	&#169;2024 Mountreach-Farmer Producer Company Limited</p>
            {/* <p>	Website Design by Pankaj Sahu</p> */}
        </div>
        <ul style={{display:"flex",listStyleType:"none",margin:0,padding:0,alignItems:"center",flexWrap:"wrap"}}>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}><Link style={{color:"White",textDecoration:"none",margin:'10px 0'}} to={"https://privacy-policy.mountreachfarmer.com"}>  Privacy Policy</Link></li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}><Link style={{color:"White",textDecoration:"none",margin:'10px 0'}} to={"https://privacy-policy.mountreachfarmer.com"}>Terms & Condition </Link></li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>Sitemap</li>
            <li style={{padding:"0 10px"}}>Refund & Cancellation Policy</li>
             </ul>
      
    </div>
  )
}

export default Footer
