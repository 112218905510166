import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "center",
    // centerMode: true,
    // centerPadding: "20px",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 940,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

  };
  

function Blogs() {

  return (
    <div  className="blogs"
    style={{
    // position: 'relative',
    margin: '20px 15%',
    paddingTop:20

  }}>
  
  <h2 style={{textAlign:'center',marginTop:'80px'}}>Our Blogs</h2>

  <div>

      <Slider {...settings}>
      <div style={{
    padding: 20,
    background: 'white',
    color: 'black',
    margin: '20px 25px',
    borderRadius: 8,
    overflow: "hidden",
    width: 250,
    fontSize: 14,
   gap:20,
   transition: "2s ease-in-out"
}}>
   <div className="card" 
    style={{ display: "grid", placeItems: "center",position: 'relative',overflow: "hidden",margin:5,transition: "2s ease-in-out"
    }}>
    <img style={{height: 300,width:'100%',objectFit: 'cover',transition: "2s ease-in"}} 
    src='https://images.pexels.com/photos/1072824/pexels-photo-1072824.jpeg?auto=compress&cs=tinysrgb&w=1200' 
    alt='img' />
    
    <div className='text' style={{
        color: "white",
        padding: '10px 20px',
        position: "absolute",
        bottom: 0,
        left: 0,
        background: '#72626252',
        borderRadius: "0 0 8px 0",
        transition: ".2s ease-in-out" 
        }}>
        <h2 style={{ margin: 0,color:"white" }}>Experienced & Trained Manpower</h2>
        <div style={{ display: "flex", fontSize: 10 }}>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>July 20</div>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>John Doe</div>
        </div>
        <div className="card-content" style={{ display: 'none' }}>
            <p >Our team consists of highly experienced and rigorously trained professionals.</p>
            <span className='button' style={{border:'1px solid #dc143c',padding:"3px 10px",borderRadius:15,background:"#dc143c",color:"white"}}>Read More</span>
        </div>
        
    </div>
</div>

    
</div>
      <div style={{
    padding: 20,
    background: 'white',
    color: 'black',
    margin: '20px 25px',
    borderRadius: 8,
    overflow: "hidden",
    width: 250,
    fontSize: 14,
   
}}>
   <div className="card" 
    style={{ display: "grid", placeItems: "center",position: 'relative',overflow: "hidden",margin:5,transition: "2s ease-in-out"
    }}>
    <img style={{height: 300,width:'100%',objectFit: 'cover',transition: "2s ease-in"}} 
    src='https://images.pexels.com/photos/974314/pexels-photo-974314.jpeg?auto=compress&cs=tinysrgb&w=1200' 
    alt='img' />
    
    <div  style={{
        color: "white",
        padding: '10px 20px',
        position: "absolute",
        bottom: 0,
        left: 0,
        background: '#72626252',
        borderRadius: "0 0 8px 0",
        transition: "2s ease-in-out" }}>
        <h2 style={{ margin: 0,color:"white" }}>Experienced & Trained Manpower</h2>
        <div style={{ display: "flex", fontSize: 10 }}>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>July 20</div>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>John Doe</div>
        </div>
        <div className="card-content" style={{ display: 'none',transition: "2s ease-in", }}>
            <p >Our team consists of highly experienced and rigorously trained professionals.</p>
            <span className='button' style={{border:'1px solid #dc143c',padding:"3px 10px",borderRadius:15,background:"#dc143c"}}>Read More</span>
        </div>
        
    </div>
</div>

    
</div>
      <div style={{
    padding: 20,
    background: 'white',
    color: 'black',
    margin: '20px 25px',
    borderRadius: 8,
    overflow: "hidden",
    width: 250,
    fontSize: 14,
   
}}>
   <div className="card" 
    style={{ display: "grid", placeItems: "center",position: 'relative',overflow: "hidden",margin:5,transition: "2s ease-in-out"
    }}>
    <img style={{height: 300,width:'100%',objectFit: 'cover',transition: "2s ease-in"}} 
    src='https://images.unsplash.com/photo-1523349122880-44486ffa7b14?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGFncmljdWx0dXJlfGVufDB8fDB8fHww' 
    alt='img' />
    
    <div  style={{
        color: "white",
        padding: '10px 20px',
        position: "absolute",
        bottom: 0,
        left: 0,
        background: '#72626252',
        borderRadius: "0 0 8px 0",
        transition: "2s ease-in-out" }}>
        <h2 style={{ margin: 0,color:"white" }}>Experienced & Trained Manpower</h2>
        <div style={{ display: "flex", fontSize: 10 }}>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>July 20</div>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>John Doe</div>
        </div>
        <div className="card-content" style={{ display: 'none',transition: "2s ease-in", }}>
            <p >Our team consists of highly experienced and rigorously trained professionals.</p>
            <span className='button' style={{border:'1px solid #dc143c',padding:"3px 10px",borderRadius:15,background:"#dc143c"}}>Read More</span>
        </div>
        
    </div>
</div>

    
</div>
      <div style={{
    padding: 20,
    background: 'white',
    color: 'black',
    margin: '20px 25px',
    borderRadius: 8,
    overflow: "hidden",
    width: 250,
    fontSize: 14,
   
}}>
   <div className="card" 
    style={{ display: "grid", placeItems: "center",position: 'relative',overflow: "hidden",margin:5
    }}>
    <img style={{height: 300,width:'100%',objectFit: 'cover',transition: "2s ease-in"}} 
    src='https://images.unsplash.com/photo-1524486361537-8ad15938e1a3?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGFncmljdWx0dXJlfGVufDB8fDB8fHww' 
    alt='img' />
    
    <div  style={{
        color: "white",
        padding: '10px 20px',
        position: "absolute",
        bottom: 0,
        left: 0,
        background: '#72626252',
        borderRadius: "0 0 8px 0",
        transition: "2s ease-in-out" }}>
        <h2 style={{ margin: 0,color:"white" }}>Experienced & Trained Manpower</h2>
        <div style={{ display: "flex", fontSize: 10 }}>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>July 20</div>
            <div style={{border:'1px solid grey',padding:"3px 10px",borderRadius:10,margin:5}}>John Doe</div>
        </div>
        <div className="card-content" style={{ display: 'none',transition: "2s ease-in", }}>
            <p >Our team consists of highly experienced and rigorously trained professionals.</p>
            <span className='button' style={{border:'1px solid #dc143c',padding:"3px 10px",borderRadius:15,background:"#dc143c"}}>Read More</span>
        </div>
        
    </div>
</div>

    
</div>
      </Slider>
    </div>


    </div>
  )
}

export default Blogs