import React from 'react'

function Header() {
  return (
    <div className='header-box' style={{
      backgroundImage:`url(${'headerlogo2.png'})`,
      backgroundSize:'cover',
      backgroundPosition:'center',
      height:'70vh',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      width:'100vw'
    }}>

      <div  style={{
        width:'40%',
        backgroundColor:'#00290430',
        padding:'40px',
        borderRadius:'20px',
        textAlign:'center'
        }}>
       
        <h1 className='header-h1'>Mountreach farmer Producer Company Limited</h1>
        <p className='header-p'>A farmer producer company (FPC) is a type of company established under the Companies Act in India, with the primary objective of benefiting farmers, primary producers, and rural communities.</p>
      </div>

      {/* <div style={{width:'40%'}}>
        <img className='header-img' src={"headerimg.png"} alt='img'/>
      </div> */}
      
    </div>
  )
}

export default Header
