import React from 'react'
import { Link } from 'react-router-dom'

const Socialmedia = ({scroll}) => {
  return (
    <div className='m0' style={{margin:"0 10%"}}>
    {/* <div style={{padding:"0 10%",background: 'radial-gradient(circle, rgba(170,242,156,1) 0%, rgba(255,255,255,1) 100%)'}}> */}
    <div className='column p0' style={{display:'flex',justifyContent:"space-evenly",padding:"50px 0"}}>

    <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:-25}}>
        <Link className='link' to={"/"}><img width={300} src={'mountlogo.png'} style={{objectFit:"cover"}} alt='logo'/></Link>
        <div className="socialmedia" style={{display:"flex",gap:10,transition:"3s ease-in"}}>
            <Link className='link' to={"https://www.facebook.com/profile.php?id=61564810981597"}> <div style={{border:"1px solid #156bb4",width:40,height:40,borderRadius:"50%",display:"grid",placeItems:"center",color:"#156bb4"}}><i style={{fontSize:15}} className="fa-brands fa-facebook-f"/></div></Link>
            <div style={{border:"1px solid #156bb4",width:40,height:40,borderRadius:"50%",display:"grid",placeItems:"center",color:"#156bb4"}}><i style={{fontSize:15}} className="fa-brands fa-youtube"/></div>
            <div style={{border:"1px solid #156bb4",width:40,height:40,borderRadius:"50%",display:"grid",placeItems:"center",color:"#156bb4"}}><i style={{fontSize:15}} className="fa-brands fa-linkedin-in"/></div>
            <Link className='link' to={"https://www.instagram.com/mountreachfpc/?hl=en"}> <div style={{border:"1px solid #156bb4",width:40,height:40,borderRadius:"50%",display:"grid",placeItems:"center",color:"#156bb4"}}><i style={{fontSize:15,fontWeight:"800"}} className="fa-brands fa-instagram"/></div></Link>
            <Link className='link' to={"https://x.com/mountreach"}> <div style={{border:"1px solid #156bb4 ",width:40,height:40,borderRadius:"50%",display:"grid",placeItems:"center",color:"#156bb4"}}><i style={{fontSize:15,fontWeight:"800"}} className="fa-brands fa-x-twitter"/></div></Link>
        </div>
      </div>
     
      <div className='contact' style={{display:'flex',justifyContent:"space-between",padding:"20px 20px",background:"#ffffffc9",borderRadius:15,gap:50}}>
      <div className='Desktop' style={{width:"30%"}}>
        <h3>Quick Links:</h3>
        <ul style={{listStyleType:"none", padding:0,margin:0,color:"black",fontWeight:600}}>
            <li onClick={() => scroll('Home')} className='slide-right' style={{color:"black",textDecoration:"none",margin:'10px 0'}}  ><i style={{color:'#156bb4',marginRight:5}} className="fa-solid fa-angles-right"></i>Home</li>
            <li onClick={() => scroll('About')} className='slide-right' style={{color:"black",textDecoration:"none",margin:'10px 0'}} ><i style={{color:'#156bb4',marginRight:5}} className="fa-solid fa-angles-right"></i>About</li>
            <li onClick={() => scroll('Blogs')} className='slide-right' style={{color:"black",textDecoration:"none",margin:'10px 0'}} ><i style={{color:'#156bb4',marginRight:5}} className="fa-solid fa-angles-right"></i>Blogs</li>
            <li  className='slide-right'  style={{color:"black",textDecoration:"none",margin:'10px 0'}} ><i style={{color:'#156bb4',marginRight:5}} className="fa-solid fa-angles-right"></i>Contact Us</li>
        </ul>

      </div>

      <div >
         <h3>Get In Touch:</h3>
         <div style={{margin:'10px 0'}}><i style={{marginRight:10,color:"#156bb4"}} className='fa fa-phone'/>Phone: +1 123 456 7890</div>
         <div style={{margin:'10px 0'}}><i style={{marginRight:10,color:"#156bb4"}} className='fa-solid fa-envelope'/>Email: <a style={{fontSize:15}} href="mailto:info@mount.com"> support@mountreachfarmer.com</a></div>
         <div style={{margin:'10px 0',display:"flex"}}><i style={{marginRight:10,color:"#156bb4"}} className='fa fa-location-dot'/><div> LIG-301,E-7, Arera Colony, Bhopal, Madhya Pradesh 462016, India</div></div>
      </div>
      </div>
     
    </div>
    </div>
  )
}

export default Socialmedia
